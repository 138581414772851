<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>子イベント登録</template>
              <template v-slot:body>
                <transition name="fadeHeight" mode="out-in" @beforeLeave="beforeLeave" @enter="enter" @afterEnter="afterEnter">
                  <router-view ref="register" />
                </transition>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid justify-content-end">
          <li class="listGrid-item" v-if="!isClientChild">
            <router-link class="btn btn-white" :to="{ name: 'SettingEventChild' }">キャンセル</router-link>
          </li>
          <li class="listGrid-item" v-if="routeName === baseRouteName">
            <button class="btn btn-bd-main" @click="changeRoute">詳細設定を登録する</button>
          </li>
          <li class="listGrid-item" v-if="routeName === detailRouteName">
            <button class="btn btn-bd-main" @click="changeRoute">基本設定を登録する</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-blue" :handle-submit="register" button-text="登録する" />
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import nav from '@/mixins/nav/setting';
import event from '@/mixins/module/event';
import fadeHeight from '@/mixins/plugin/fadeHeight';

export default {
  name: 'SettingEventRegisterChild',
  data: function() {
    return {
      pageName: '設定',
      baseRouteName: 'SettingEventChildRegisterBase',
      detailRouteName: 'SettingEventChildRegisterDetail',
    };
  },
  mixins: [nav, fadeHeight, event],
  computed: {
    ...mapGetters({
      eventForm: 'event/newEvent',
      parentEventDetail: 'event/parentEventDetail',
      isClientChild: 'auth/isClientChild',
    }),
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    parentEventDetail(event) {
      this.checkPossibleCreateFlag(event);
    },
  },
  methods: {
    register: async function() {
      const isPassValidation = this.$refs.register.validate()
      if (isPassValidation) {
        const payload = this.convertForm(this.eventForm);
        const result = await this.$store.dispatch('event/createChildEvent', payload);
        if (result) {
          await this.$router.push({ name: 'SettingEventChild' });
          this.$message.created('childEvent');
        }
      }
    },
    changeRoute: function() {
      const isPassValidation = this.$refs.register.validate()
      if (isPassValidation) {
        if (this.routeName === this.baseRouteName) {
          this.$router.push({ name: this.detailRouteName })
        } else {
          this.$router.push({ name: this.baseRouteName })
        }
      }
    },
    checkPossibleCreateFlag: function(parentEvent) {
      if (
        parentEvent.subdomain &&
        parentEvent.subdomain === this.$route.params.subdomain &&
        !parentEvent.isPossibleCreate
      ) {
        this.$router.push({ name: '404' });
      }
    },
  },
  created() {
    this.checkPossibleCreateFlag(this.parentEventDetail);
  }
};
</script>
